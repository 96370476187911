import React, { useEffect } from "react";
import Layout from '../components/layout'
import { graphql } from "gatsby"
import ContactForm from '../components/contact-form'
import PageHeader from "../components/page-header";
import ContentGrid from "../components/content-grid";
import { gsap } from 'gsap'
import utils from "../components/utils";
import CircleLink from "../components/circle-link";
import SEO from "../components/seo";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../cms-helpers";

export default function ReferralsPage({ data }) {
  useEffect(() => {
    utils.resetPage()

    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: "#referall-content",
        start: "top top+=100px",
        end: "bottom top+=100px",
        scroller: "#scroll-container",
        onEnter() {
          utils.fadeNav(true)
        },
        onEnterBack() {
          utils.fadeNav(true)
        },
        onLeave() {
          utils.fadeNav(false)
        },
        onLeaveBack() {
          utils.fadeNav(false)
        }
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  const page = data.contentfulPageReferrals

  return (
    <Layout>
      <SEO
        title={page.meta.title}
        description={page.meta.description}
        image={page.meta.image.fluid.src}
      />

      <PageHeader image={page.headerImage.fluid} title={page.title} />
      <ContentGrid
        title={page.content.title}
        description={page.content.shortDescription.shortDescription}
        image={page.content.image.fluid}
        left={true}
      />
      <section id="referall-content" className="bg-dark px-4 lg:px-0 h-screen md-h-75vh text-sand flex items-center">
        <div className="max-w-2xl mx-auto about-content referals-content">
          {renderRichText(page.mainText, options)}
        </div>
      </section>
      <section className="min-h-screen flex items-center">
        <div className="max-w-lg mx-auto px-4 lg:px-0 text-center">
          <h2 className="font-sc text-lg lg:text-xl mb-4 capitalize">{page.referralPartnerTitle}</h2>
          <p className="mb-8">
            {page.referralPartnerDescription}
          </p>
          <CircleLink title="Contact Us" to="/contact" isLink={true} onPress={() => {
            gsap.to('.dark-bg-mask', {
              translateY: 0,
              ease: "power3.out",
              duration: 1,
              onComplete: () => {
                utils.fadeNav(true)
              }
            })
          }} />
        </div>

      </section>
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPageReferrals {
      title
      referralPartnerDescription
      referralPartnerTitle
      headerImage {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mainText {
        raw
      }
      content {
        title
        shortDescription {
          shortDescription
        }
        image {
          fluid(quality: 90, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`


